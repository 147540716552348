import animateScrollTo from "animated-scroll-to";

// key名は露出するため簡素に
const accessLogkey = "log";

$(() => {
  setCurrentPathForAccessLog();
  setGoogleAnalyticsEventEmitter();
  setSlider();
  setAsideSticky();
  setTocScrollAnimation();
  setFlashMessageClose();
  setMenuTriggerActive();
  setupEventPage();
  setupEventDetailPage();
  setupPasswordConfirm();
  setupLoggedInTop();
  setToastr();
  setModal();

  if (location.pathname.startsWith("/business")) {
    setupBusinessPage();
  }
  if (location.pathname.startsWith("/support")) {
    setupSupportPage();
  }
  if (location.pathname.startsWith("/register")) {
    setupRegisterPage();
  }
  if (location.pathname.startsWith("/university/class")) {
    setupUniversityClassPage();
  }
});

function setSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  $(".js-slider").addClass("shown").slick(settings);
}

function setupLoggedInTop() {
  if (!localStorage.getItem("scoutFlowPopupClosed")) {
    $(".js-scoutFlow").css({ display: "block" });
  }
  $(".js-close").on("click", () => {
    $(".js-scoutFlow").css({ display: "none" });
    localStorage.setItem("scoutFlowPopupClosed", "1");
  });
}

function setupBusinessPage() {
  // $(".answer").css({ display: "none" });
  // $(".questionText").on("click", function () {
  //   $(this).next(".answer").slideToggle();
  //   $(this).toggleClass("open");
  // });
}

function setAsideSticky() {
  const $w = $(window);
  const $a = $(".aside-inner");

  $a.css("top", `${$w.height() - $a.height() - 20}px`);
  window.setInterval(
    () => $a.css("top", `${$w.height() - $a.height() - 20}px`),
    1000
  );
}

function setTocScrollAnimation() {
  $("#toc_container a").on("click", (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const elm = document.querySelector(
      `[id='${ev.target.getAttribute("href").replace("#", "")}']`
    );
    animateScrollTo(elm, {
      verticalOffset: -100,
      speed: 100,
    });
  });
}

function setFlashMessageClose() {
  $(".flashMessage .closeBtn").on("click", () => {
    $(".flashMessage").fadeOut();
  });
}

function setMenuTriggerActive() {
  $(".sp_menu_tregger").on("click", () => {
    $(".sp_menu_tregger").toggleClass("on");
    $(".sp_menu_wrapper").toggleClass("on");
  });
}

function setupSupportPage() {
  $(".js-scroll-form").on("click", () => {
    const elm = document.querySelector("#supportForm");
    animateScrollTo(elm, {
      verticalOffset: -140,
      speed: 100,
    });
  });

  // ログイン中の場合に自動で項目を埋める
  $(document).on("click", ".selectPanel", () => {
    if (!window.userData) {
      return;
    }
    window.setTimeout(() => {
      $("#booking_package_input_lastname").val(window.userData.nickname || "");
      $("#booking_package_input_email").val(window.userData.user_email || "");
      $("#booking_package_input_phone").val(window.userData.tel || "");
      $("#booking_package_input_number").val(window.userData.tel || "");
    }, 100);

    // 完了時にAPIを叩く
    // 日付を選択してから監視を始める
    // 適切なhookが存在しないので、画面を監視して対象の文字が出現しているかを判定する
    var timer = null;
    timer = window.setInterval(() => {
      const $headerText = $("#reservationHeader");
      const text = $headerText.text();
      if (text === "予約手続きが完了しました") {
        window.clearInterval(timer);
        postUserLoggerApi();
      }
    }, 1000);
  });

  $(".js-scroll-interview").on("click", () => {
    const elm = document.querySelector("#interview");
    animateScrollTo(elm, {
      verticalOffset: -100,
      speed: 100,
    });
  });

  // フォームに到達したら固定フッターを隠す
  const $fixedFooter = $("#supportFixedFooter");

  // 今回の交差を監視する要素
  const form = document.getElementById("supportForm");

  const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "-50% 0px", // ビューポートの中心を判定基準にする
    threshold: [0, 0.25, 0.5, 0.75, 1],
  };
  const observer = new IntersectionObserver(doWhenIntersect, options);

  observer.observe(form);

  function doWhenIntersect(entries) {
    // 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        $fixedFooter.addClass("hidden");
      } else {
        if (form.getBoundingClientRect().y > 0) {
          $fixedFooter.removeClass("hidden");
        }
      }
    });
  }
}

function postUserLoggerApi() {
  // APIにデータを送る
  const values = [];
  $("#booking-package_inputFormPanel .row").each((_, x) =>
    values.push($(x).find(".value").text())
  );
  const data = {
    time: values[0],
    name: values[1],
    email: values[2],
    tel: values[3],
    access_log: localStorage.getItem(accessLogkey),
  };

  console.log(data);
  $.ajax({
    url: "/wp-json/users/log",
    type: "POST",
    dataType: "json",
    data,
    timeout: 3000,
  })
    .done(function (data) {})
    .fail(function (XMLHttpRequest, textStatus, errorThrown) {});
}

function setupEventPage() {
  $(".calendar").flatpickr({
    locale: "ja",
  });

  $(".js-change-target").on("click", (ev) => {
    const target = $(ev.currentTarget).data().target;
    const $form = $("form");
    $form
      .find(`[name='target'] option[value='${target}']`)
      .prop(
        "selected",
        $(ev.currentTarget).hasClass("selected") ? false : true
      );
    $form.trigger("submit");
  });

  $(".js-filter-trigger").on("click", () => {
    $(".js-filter-trigger").toggleClass("isOpened");
    $(".js-filter-content").slideToggle();
  });
}

function setupEventDetailPage() {
  const $accordion = $(".js-event-accordion.isMobile");
  const $accordionTrigger = $(".accrodion_trigger");
  $accordion.addClass("hidden");

  $accordionTrigger.on("click", () => {
    $accordion.removeClass("hidden");
    $accordionTrigger.addClass("hidden");
  });

  // PC時、stickyのために高さを固定
  $(".js-sticky-area").height($(document).height());
}

function setupPasswordConfirm() {
  const $pass_A = $(".js-new-password"); // これがキー
  const $pass_B = $("[name='password_confirm']");
  const $submit = $("[type='submit']");

  // 関係ないページでは行わない
  if ($pass_A.length == 0) {
    return;
  }

  // confirmが存在するなら、通過しない限りsubmit出来ない
  if ($pass_B.length > 0) {
    $submit.prop("disabled", true);
  }

  // パスワードが要件を満たしていることを確認
  $pass_A.on("blur", () => {
    if ($pass_A.val() == "") {
      return;
    }

    if (!validatePassword($pass_A.val())) {
      alert(
        "半角英数字をそれぞれ1種類以上含む、8文字以上のパスワードを入力して下さい"
      );
      $pass_A.val("");
      $submit.prop("disabled", true);
      return;
    }

    // 一致している場合にcssを解除
    $submit.prop("disabled", false);
  });

  // パスワードが同じであることを確認
  $pass_B.on("blur", () => {
    if ($pass_A.val() == "" || $pass_B.val() == "") {
      return;
    }
    if ($pass_A.val() !== $pass_B.val()) {
      alert("パスワードが一致しませんでした。入力内容を確認してください。");
      $pass_B.val("");
      $submit.prop("disabled", true);
      return;
    }

    // 一致している場合にcssを解除
    $submit.prop("disabled", false);
  });
}

function setGoogleAnalyticsEventEmitter() {
  $(document).on("click", "*[data-event-action]", function (ev) {
    const $this = $(this);
    const next_path = $this.prop("href")
      ? new URL($this.prop("href")).pathname
      : null;
    const current_path = location.pathname;
    const event_category = $this.data("event-category");
    const event_label = $this.data("event-label");
    const action = $this.data("event-action");
    const value = $this.data("event-value");

    const data = {
      event_category,
      event_label,
      value,
      next_path,
      current_path,
    };
    gtag("event", action, data);
  });

  // 投稿内のリンクをクリック時にイベントを記録する
  $(document).on("click", "article a", function (ev) {
    const $this = $(this);
    const next_path = new URL($this.prop("href")).pathname;
    const current_path = location.pathname;
    const event_category = next_path.startsWith("/support")
      ? "link-to-support"
      : next_path.startsWith("/event")
      ? "link-to-event"
      : next_path.startsWith("/blog")
      ? "link-to-post"
      : "link-to-top";
    const event_label = "page-post";
    const action = "click";
    const value = null;

    const data = {
      event_category,
      event_label,
      value,
      next_path,
      current_path,
    };

    gtag("event", action, data);
  });

  // 予約完了時にイベントを送る
  if (location.pathname.startsWith("/support")) {
    // クリックイベントを取得することが出来ないので、1秒ごとにテキストの存在を確認することで完了を判断する
    var timer = null;
    timer = window.setInterval(() => {
      if ($(".booking_completed_panel").size() > 0) {
        gtag("event", "conversion", {
          event_category: "support-booking-completed",
          event_label: "page-support",
          current_path: location.pathname,
        });

        window.clearInterval(timer);
      }
    }, 1000);
  }
}

// パスワード設定時に以下を確認
// - 設定可能な文字列で構成されているか
// - 問題なければtrue、あればfalse
function validatePassword(str) {
  // 文字列が要件を満たしているかを確認
  const pattern = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/i;
  return pattern.test(str);
}

/**
 * 会員登録時のためにアクセスログを保存する
 * @returns void
 */
function setCurrentPathForAccessLog() {
  let paths = [];

  // ログイン中は記録せず、logが存在する場合は削除する
  if (window.userData && window.userData.user_email) {
    localStorage.removeItem(accessLogkey);
    return;
  }

  // 過去にアクセスしたことがあるなら、その値を取得する
  const log = localStorage.getItem(accessLogkey);
  if (log) {
    try {
      paths = JSON.parse(log);
    } catch (error) {
      console.log("clear");
      paths = [];
    }
  }

  const path = location.pathname + location.search;

  // 更新をカウントしない
  if (paths.length > 0 && paths[paths.length - 1].path === path) {
    return;
  }
  paths.push({
    time: new Date().toLocaleString(),
    path: path,
  });

  // 現在のパスを保存
  localStorage.setItem(accessLogkey, JSON.stringify(paths));
}

function setupRegisterPage() {
  const log = localStorage.getItem(accessLogkey);
  $(".js-access-log").val(log);
  console.log(123222);

  // #register-formのsubmit時にreCAPTCHAを実行する
  $("#register-form").on("submit", function (ev) {
    const $form = $(this);
    ev.preventDefault();

    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LcT7Y0eAAAAADOFRFzzJyo_5uiHjL9VF3ysME5X", {
          action: "submit",
        })
        .then(function (token) {
          // フォームを送信
          $form.append(
            `<input type="hidden" name="recaptchaResponse" value="${token}">`
          );
          $form.off("submit");
          $form.trigger("submit");
        });
    });
  });
}

function setToastr() {
  /**
   *
   * @param {string} type
   * @param {string} key
   * @param {string} message
   * @returns
   */
  function set(type, key, message) {
    toastr.options = {
      positionClass: "toast-top-center",
    };
    if (!Cookies.get(key)) {
      return;
    } else {
      toastr[type](message);
      Cookies.remove(key);
    }
  }

  set("success", "logged-in", "ログインが完了しました");
  set("success", "logged-out", "ログアウトが完了しました");
}

function setModal() {
  /**
   *
   * @param {string} key
   * @returns
   */
  function set(key) {
    if (!Cookies.get(key)) {
      return;
    } else {
      // モーダルを表示
      $(`.js-modal[modal-key="${key}"]`).fadeIn();

      // 閉じるボタンにイベント
      $(".js-preventDefault").on("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      });

      // 閉じるボタンにイベント
      $(".js-close-modal").on("click", () => {
        $(`.js-modal[modal-key="${key}"]`).fadeOut();
        gtag("event", "conversion", {
          event_category: "register-completed",
          event_label: "page-register",
          current_path: location.pathname,
        });

        Cookies.remove(key);
      });
    }
  }

  set("hasRegistered");
}

function setupUniversityClassPage() {
  const $trigger = $(".js-form-link");

  $trigger.on("click", (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const elm = document.querySelector(`#form`);
    animateScrollTo(elm, {
      verticalOffset: -100,
      speed: 100,
    });
  });
}
